(function($) {
	/**
	 * -------------------
	 * DEFAULT SCRIPTS
	 * -------------------
	 * Comment out the scripts you are not using. The compiler will get rid of the comments and minify for you.
	 */
 
	$(window).on('load shopify:section:load', function () {

		// Accordion for FAQs (jQuery)
		$('.accordion .accordion__item.active dd').slideDown()
		$('.accordion').on('click', '.accordion__item', function () {
			$('.accordion dd').slideUp();

			if (!$(this).hasClass('active')) {
				// remove active class from any others that might be active
				$('.accordion .accordion__item.active').removeClass('active');
				$(this).addClass('active');
				$(this).find('dd').slideDown();
			} else {
				$(this).removeClass('active');
			}
		});

        $('.header-hamburger').click(function(){
            $('body').toggleClass('menu-open');
        });
	
		// Components loading animations
		$('.view-animation').viewportChecker({
			classToAdd: 'animated',
			offset: 20
		});

		// Lazyload
		$('[data-src]').Lazy({
			effect: 'fadeIn',
			visibleOnly: true,
			onError: function (element) {
				console.log('error loading ' + element.data('src'));
			}
		});

        // Mobile subnav opener
        $('.dropdown-trigger').click(function(e){
            e.preventDefault();
            $(this).toggleClass('active');
            $('.sub-menu', $(this).closest('li')).toggleClass('active');
        });

        $(document).on('click', '.inline-video-trigger', function () {
            if ($(this).data('video-id')) {
                if ($(this).hasClass('vimeo')) {
                    var iframeHTML = '<iframe src="https://player.vimeo.com/video/' + $(this).attr('data-video-id') + '?title=0&byline=0&portrait=0?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
                } else {
                    var iframeHTML = '<iframe src="https://www.youtube.com/embed/' + $(this).attr('data-video-id') + '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
                }

                $(this).parent('.video-preview-container').find('.inline-video-trigger').hide();
                $(this).parent('.video-preview-container').addClass('playing');
                $(this).parent('.video-preview-container').find('.overlay').hide();
                $(this).parent('.video-preview-container').find('iframe').remove();
                $(this).parent('.video-preview-container').append(iframeHTML);
            } else {
                console.error('no video ID provided.');
            }
        });

		// Phone Concatenation Script For Tracking
		setTimeout(function () {
            $('.phone-text em').each(function () {
                var unsliced = $(this).text();
                var sliced = unsliced.slice(0, -2) + "...";
                $(this).text(sliced);
                var linked = "tel:" + unsliced.replace(/\s/g, '');
                $(this).click(function () {
                    if ($(window).width() < 1000) {
                        window.location.href = linked;
                    } else {
                        $(this).text(unsliced);
                    }
                });
            });

        }, 3000);
            
        if($('#timer').length){
            var timeduration = $('.announcements').data('duration');
            var timetype = $('.announcements').data('type');
            startTimer(timeduration, timetype, $('#timer')[0]);
        }

	});



	// Sticky Header
   responsiveAnnouncementBar();
	$(window).on("scroll load", function () {
		if ($(window).scrollTop() >= 50) {
			$('header').addClass('scrolled');
		} else {
			$('header').removeClass('scrolled');
		}
	});

	 // Smooth Scroll To Anchor
     $(document).on('click', 'a[href*="#"]', function (event) {
        event.preventDefault()
        // Get the hash. In this example, "#myDestinationAnchor".
        var targetSelector = this.hash
        var $target = $(targetSelector)
    
        // Animate the scroll to the destination...
        $('html, body').animate(
          {
            scrollTop: $target.offset().top - 40 // Scroll to this location.
          },
          {
            // Set the duration long enough to allow time
            // to lazy load the elements.
            duration: 2000,
    
            // At each animation step, check whether the target has moved.
            step: function (now, fx) {
              // Where is the target now located on the page?
              // i.e. its location will change as images etc. are lazy loaded
              var newOffset = $target.offset().top - 40
    
              // If where we were originally planning to scroll to is not
              // the same as the new offset (newOffset) then change where
              // the animation is scrolling to (fx.end).
              if (fx.end !== newOffset) fx.end = newOffset
            }
          }
        )
      })

    var resizeTimer;
    $(window).bind('resize', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
           responsiveAnnouncementBar();
        }, 100);
    });

	function responsiveAnnouncementBar(){
		// announcement bar can be of varying height due to content, especially mobile
		// this just creates css for the fixed header so the offset values are correct
        var anbarHeight = $('.announcements').outerHeight();
        var headerHeight = $('.site-header').outerHeight();
        var headerStyleDiv = $('#header-styles');

        var newStyles = ''+
        'body.fixed-header.has-announcements .site-header.scrolled,'+
        'body.fixed-header .site-header.hide-announcements{'+
            'transform: translateY(-'+anbarHeight+'px);'+
        '}';

        newStyles += ''+
        'body.fixed-header.has-announcements {'+
            'padding-top: '+headerHeight+'px;'+
        '}';

        headerStyleDiv.html(newStyles);
    }

    // Exit intent
    /*
    var hasOpenedModal = false;
    if(!getCookie('nlpopup-'+window.sitename)){
        setTimeout(function(){
            document.addEventListener("mouseout", function(evt){
                if(!evt.toElement && !evt.relatedTarget && !hasOpenedModal && !$('#prescribeForm').hasClass('show')) {
                    $('#newsletterForm').modal(true);
                    hasOpenedModal = true;
                    setCookie('nlpopup-'+window.sitename, true, 1);
                }
            });
        }, 3000);
    }
    */
    function setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    // Countdown timer
    /*
    function startTimer(duration, type, display) {
        var timer = duration;
        var minutes, seconds, hours, days;
        var now = Math.floor(Date.now() / 1000);

        if(type == 'evergreen'){
	        var nearestblock = Math.ceil(now / (duration * 60)) * (duration * 60);
	        timer = (nearestblock - now);
        }

        if(type == 'to_date'){
	        var dateparts = duration.split(' ')[0];
	        var timeparts = duration.split(' ')[1];

	        // we need to 0 index the month
	        var datestring = dateparts[0]+'-'+(dateparts[1]-1)+'-'+dateparts[2]+' ';
	        datestring += timeparts;
	        var todate = Date.parse(duration) / 1000;
	        timer = (todate - now);
        }
        
        setInterval(function () {
            minutes = parseInt(timer / 60, 10) % 60;
            seconds = parseInt(timer % 60, 10);

            hours = parseInt(timer / 60 / 60, 10) % 24;
            days = parseInt(timer / 60 / 60 / 24, 10);

            days = days < 10 ? "0" + days : days;
            hours = hours < 10 ? "0" + hours : hours;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            if(days > 0){
                display.textContent = days + ":" + hours + ":" + minutes + ":" + seconds;
            }else{
                display.textContent = hours + ":" + minutes + ":" + seconds;
            }

            if (--timer < 0) {
                timer = 0;
                hideBar();
            }
        }, 1000);

        function hideBar(){
        	if($('.announcements').hasClass('hide-after-time')){
        		$('body').removeClass('has-announcements');
        		$('.main-header').addClass('hide-announcements');
        	}
        }
        
    }
    */



})( jQuery );